import * as React from "react";
import { Divider } from "../components/Divider";
import { Title } from "../components/Typography";
import { ActionBox } from "../components/ActionBox";
import { Button } from "../components/Button";
import talentShow2022 from "../assets/photos/talentShow2022-2.jpg";
import Friends5 from "../assets/photos/friends5.jpg";
import Friends from "../assets/photos/friends.jpg";
import { urls } from "../helpers/urls";
import { padding, transparentize } from "polished";
import styled from "@emotion/styled";
import TalentShowApplication from "../assets/2025SeniorTalentShowApplicationAndFlyer.pdf";
import talentShow from "../assets/photos/talentShow11.jpg";

/** Hero stock image found here
 * https://www.pexels.com/photo/joyful-adult-daughter-greeting-happy-surprised-senior-mother-in-garden-3768131
 * Photo by Andrea Piacquadio from Pexels
 * Licensing as of 10/24/2021 says:
 *  ✓ Free to use.
 *  ✓ No attribution required.
 */
import HeroImage from "../assets/hero.jpg";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { useTheme } from "@emotion/react";
import { WeServe } from "../components/WeServe";
import { margin } from "polished";
import { SideBySide } from "../components/SideBySide";
import { Link } from "../components/Link";

const Accent = styled.span<{ variant?: "red" | "black" }>(
  ({ theme, variant = "red" }) => ({
    ...theme.typography.Subtitle,
    ...theme.typography.accent,
    color: theme.colors[variant],
  })
);

const Actions = styled.div(({ theme }) => ({
  display: "flex",
  gap: 16,
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
    padding: 0,
  },
}));

const Stats = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  ...padding(24, 0, 16, 0),
  gap: 40,
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const heroSizeDesktop = 500;
const heroSizeTablet = 400;
const heroSizeMobile = 300;

const navPaddingDesktop = 112;
const navPaddingTablet = 96;
const navPaddingMobile = 72;

const Wrapper = styled.div(({ theme }) => ({
  paddingTop: heroSizeDesktop - navPaddingDesktop,
  [theme.breakpoints.tabletLandscape]: {
    paddingTop: heroSizeTablet - navPaddingTablet,
  },
  [theme.breakpoints.phone]: {
    paddingTop: heroSizeMobile - navPaddingMobile,
  },
}));

const Hero = styled.div(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  backgroundImage: `url("${HeroImage}")`,
  backgroundSize: "cover",
  backgroundPosition: "center 30%",
  height: heroSizeDesktop,
  width: "100vw",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.tabletLandscape]: {
    height: heroSizeTablet,
  },
  [theme.breakpoints.phone]: {
    height: heroSizeMobile,
  },
}));

const TitleWrapper = styled.div(({ theme }) => ({
  color: theme.colors.black,
  width: "100%",
  textAlign: "center",
}));

const Header = styled.div(({ theme }) => ({
  ...theme.typography.HeaderB,
  ...theme.typography.accent,
}));

const Announcement = styled.div(({ theme }) => ({
  ...margin(24, 40, 0, 0),
  boxShadow: theme.shadows.cardShadow,
  padding: 20,
  borderRadius: theme.borders.radius.default,
  background: transparentize(0.2, theme.colors.gray),
  width: 500,
  [theme.breakpoints.phone]: {
    display: "none",
  },
}));

const AnnouncementTitle = styled.div(({ theme }) => ({
  ...theme.typography.BodyB,
  flexGrow: 1,
  width: "100%",
}));

const ButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "flex-end",
});

const Stat = styled.div(({ theme }) => ({
  ...padding(0, 20),
  ...theme.typography.Header,
  color: theme.colors.aqua,
  textAlign: "center",
  maxWidth: 300,
  [theme.breakpoints.phone]: {
    ":last-child": {
      paddingBottom: 0,
    },
  },
}));

const StatNumber = styled.div(({ theme }) => ({
  ...theme.typography.Subtitle,
  color: theme.colors.black,
  lineHeight: "40px",
  paddingBottom: 12,
}));

const spotlightId = "section-fiftieth-anniversary";
const onClickAnnouncement = () => {
  document?.getElementById(spotlightId)?.scrollIntoView();
};

export const Home: React.FC = () => {
  const defaultTheme = useTheme();
  const isPhone = useBreakpoint(defaultTheme.breakpoints.phone);
  return (
    <>
      <Hero aria-label="Background image woman hugging senior">
        <Announcement>
          <AnnouncementTitle>
            JOIN US FOR OUR 31st SENIOR TALENT SHOW <br />
            April 26th, 2025 • 2pm • 8th Avenue & 20th Street
          </AnnouncementTitle>
          <p>
            The Annual Senior Talent Show celebrates the spirit, vibrancy, and
            talent of seniors.
          </p>
          <ButtonWrapper>
            <Button variation="tertiary" onClick={onClickAnnouncement}>
              Learn more
            </Button>
          </ButtonWrapper>
        </Announcement>
      </Hero>
      <Wrapper>
        <TitleWrapper>
          <Title>Welcome to Visiting Neighbors!</Title>
          <Header>
            <i>&ldquo;Neighbors helping neighbors&rdquo; since 1972</i>
          </Header>
        </TitleWrapper>
        <p>
          With 50+ years of experience, Visiting Neighbors has been helping
          seniors in New York City live meaningful and active lives by matching
          them with one of our dedicated volunteers. Our programs and services
          help seniors maintain their independence by connecting them with
          someone who cares. Our goal is to provide a helping hand to our
          community's elderly, who may not have family or friends nearby.
        </p>
        <Stats>
          <Stat color={defaultTheme.colors.aqua}>
            <StatNumber>
              44,000+
              {isPhone ? " " : <br />}
              seniors
            </StatNumber>
            Assisted by Visiting Neighbors since 1972
          </Stat>
          <Stat color={defaultTheme.colors.red}>
            <StatNumber>
              11,000+
              {isPhone ? " " : <br />}
              neighbors
            </StatNumber>
            Served through Shop & Escort and Friendly Visiting
          </Stat>
          <Stat color={defaultTheme.colors.red}>
            <StatNumber>
              13,000+
              {isPhone ? " " : <br />}
              hours
            </StatNumber>
            Of telephone reassurance in 2021
          </Stat>
        </Stats>
        <WeServe />
        <Divider />
        <Actions>
          <ActionBox
            imgAlt="Steve with senior"
            imgSrc={Friends5}
            cta="Explore"
            url={urls.programs.main}
            title="Our programs"
          >
            Visiting Neighbors provides a lifeline, keeping seniors independent
            and connected to the community.
          </ActionBox>
          <ActionBox
            imgAlt="Performer at the talent show"
            imgSrc={talentShow2022}
            cta="Donate"
            url={urls.donate}
            title="Giving"
          >
            Every contribution goes towards helping seniors and supporting
            neighborhood programs.
          </ActionBox>
          <ActionBox
            imgAlt="Two friends together"
            imgSrc={Friends}
            cta="Volunteer"
            url={urls.volunteer.getInvolved}
            title="Get involved"
          >
            From shopping to playing cards, we have several opportunities to
            connect with seniors.
          </ActionBox>
        </Actions>
      </Wrapper>
      <Divider id={spotlightId} />
      <SideBySide imgSrc={talentShow} imgAlt="Talent show" imageMaxWidth={350}>
        <b>
          VN’s ANNUAL SENIOR TALENT SHOW CELEBRATES THE SPIRIT, VIBRANCY AND
          TALENT OF OLDER ADULTS.{" "}
        </b>
        <p>
          <b>As part of the Chelsea Day Festival, we will hold our</b>
        </p>
        <Accent>31st Annual Senior Talent Show</Accent>
        <br />
        <b>
          Saturday, April 26th
          <br />
          2-4pm at 8th Avenue & 20th Street
        </b>
        <p>
          We couldn’t be more excited for the <b>31st year</b> celebrating older
          New Yorkers and the special dimension of vitality and wisdom they
          promote in our great city. Visiting Neighbors gives singers, dancers,
          and comedians a chance to strut their stuff before large crowds,
          demonstrating that “Talent is Ageless.” This event is sure to warm
          your heart, help dissolve stereotypes about what seniors can – and
          can’t - do and is an affirmation of the vigor and verve of older
          adults. Come cheer on your favorites. This event is free to the
          public.
        </p>
        <p>
          <b>
            If you are a New Yorker over 60 and interested in participating as a
            performer, either call our office or{" "}
            <Link
              href={TalentShowApplication}
              variation="highlighted"
              openInNewWindow
            >
              download the application
            </Link>
            , fill it in, and e-mail it to{" "}
            <Link
              href="mailto:info@visitingneighbors.org"
              variation="highlighted"
            >
              info@visitingneighbors.org
            </Link>
          </b>
        </p>
      </SideBySide>
    </>
  );
};
